var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "project-card"
  }, [_c('el-form', {
    ref: "form",
    staticClass: "project-card__form",
    attrs: {
      "model": _vm.form,
      "rules": _vm.rules,
      "hide-required-asterisk": true
    },
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm($event);
      }
    }
  }, [_c('div', {
    staticClass: "project-card__form-data"
  }, [_c('div', {
    staticClass: "project-card__form-data__info"
  }, [_c('div', {
    staticClass: "project-card__form-data__info--left"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Название проекта",
      "prop": "title"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Адрес",
      "prop": "address"
    }
  }, [_c('el-autocomplete', {
    attrs: {
      "fetch-suggestions": _vm.querySearch,
      "placeholder": "Please Input",
      "trigger-on-focus": true
    },
    on: {
      "select": _vm.handleSelect
    },
    model: {
      value: _vm.form.address,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "address", $$v);
      },
      expression: "form.address"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Статус",
      "prop": "is_complete"
    }
  }, [_c('el-select', {
    attrs: {
      "placeholder": "Выберите статус"
    },
    model: {
      value: _vm.form.is_complete,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "is_complete", $$v);
      },
      expression: "form.is_complete"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "Активен",
      "value": 1
    }
  }), _c('el-option', {
    attrs: {
      "label": "Завершен",
      "value": 0
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "project-card__form-data__info--right"
  }, [_c('el-form-item', {
    attrs: {
      "label": "Дата начала работ",
      "prop": "start_work"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd.MM.yyyy",
      "picker-options": _vm.pickerOptions,
      "value-format": "yyyy-MM-dd HH:mm:ss"
    },
    model: {
      value: _vm.form.start_work,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "start_work", $$v);
      },
      expression: "form.start_work"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Дата окончания работ",
      "prop": "end_work"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd.MM.yyyy",
      "picker-options": _vm.pickerOptions,
      "value-format": "yyyy-MM-dd HH:mm:ss"
    },
    model: {
      value: _vm.form.end_work,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "end_work", $$v);
      },
      expression: "form.end_work"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Радуис контроля (м.)",
      "prop": "radius"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.radius,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "radius", $$v);
      },
      expression: "form.radius"
    }
  })], 1)], 1), _c('div', {
    staticClass: "project-card__form-data__info--bottom"
  }, [_vm.role === 'admin' ? _c('el-form-item', {
    attrs: {
      "label": "Компания",
      "prop": "company_id"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "Выберите компанию"
    },
    model: {
      value: _vm.form.company_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "company_id", $$v);
      },
      expression: "form.company_id"
    }
  }, _vm._l(_vm.companiesList, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.title,
        "value": item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "Описание проекта",
      "prop": "description"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "resize": "none",
      "rows": 6
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "project-card__form-data__map"
  }, [_c('div', {
    staticClass: "project-card__form-data__map-title"
  }, [_vm._v(" Карта ")]), _c('div', {
    staticClass: "project-card__form-data__map-content",
    attrs: {
      "id": "map"
    }
  })])]), _c('div', {
    staticClass: "project-card__form-buttons"
  }, [_c('router-link', {
    staticClass: "el-button",
    attrs: {
      "to": {
        name: 'Projects'
      }
    }
  }, [_vm._v(" Отменить ")]), _c('el-button', {
    attrs: {
      "type": "primary",
      "native-type": "submit",
      "disabled": _vm.isDisabled
    }
  }, [_vm._v(" Сохранить изменения ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }