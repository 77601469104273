<template>
  <div class="project-card">
    <el-form class="project-card__form" ref="form" :model="form" :rules="rules" :hide-required-asterisk="true"
      @submit.native.prevent="submitForm">
      <div class="project-card__form-data">
        <div class="project-card__form-data__info">
          <div class="project-card__form-data__info--left">
            <el-form-item label="Название проекта" prop="title">
              <el-input v-model="form.title" type="text" />
            </el-form-item>
            <el-form-item label="Адрес" prop="address">
              <el-autocomplete v-model="form.address" :fetch-suggestions="querySearch" placeholder="Please Input"
                :trigger-on-focus="true" @select="handleSelect"></el-autocomplete>
            </el-form-item>
            <el-form-item label="Статус" prop="is_complete">
              <el-select v-model="form.is_complete" placeholder="Выберите статус">
                <el-option label="Активен" :value="1" />
                <el-option label="Завершен" :value="0" />
              </el-select>
            </el-form-item>
          </div>
          <div class="project-card__form-data__info--right">
            <el-form-item label="Дата начала работ" prop="start_work">
              <el-date-picker v-model="form.start_work" type="date" format="dd.MM.yyyy" :picker-options="pickerOptions"
                value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
            <el-form-item label="Дата окончания работ" prop="end_work">
              <el-date-picker v-model="form.end_work" type="date" format="dd.MM.yyyy" :picker-options="pickerOptions"
                value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
            <el-form-item label="Радуис контроля (м.)" prop="radius">
              <el-input v-model="form.radius" type="text" />
            </el-form-item>
          </div>
          <div class="project-card__form-data__info--bottom">
            <el-form-item v-if="role === 'admin'" label="Компания" prop="company_id">
              <el-select v-model="form.company_id" filterable placeholder="Выберите компанию">
                <el-option v-for="item in companiesList" :key="item.id" :label="item.title" :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item label="Описание проекта" prop="description">
              <el-input v-model="form.description" type="textarea" resize="none" :rows="6" />
            </el-form-item>
          </div>
        </div>
        <div class="project-card__form-data__map">
          <div class="project-card__form-data__map-title">
            Карта
          </div>
          <div class="project-card__form-data__map-content" id="map" />
        </div>
      </div>
      <div class="project-card__form-buttons">
        <router-link :to="{ name: 'Projects' }" class="el-button">
          Отменить
        </router-link>
        <el-button type="primary" native-type="submit" :disabled="isDisabled">
          Сохранить изменения
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { loadYmap } from 'vue-yandex-maps'

export default {
  name: 'ProjectCard',
  props: {
    dataInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pickerOptions:{
        firstDayOfWeek:1
      },
      settings: {
        // apiKey: '59bbc8f5-c188-4b88-9e47-7c953964f1e7',
        apiKey: '7ded22f0-139b-4267-a197-a14d3c76a78e',
        lang: 'ru_RU',
      },
      coords: [55.75165026149063, 37.62172952704088],
      marker: require('../../assets/icons/icon-location.svg'),
      form: {
        title: '',
        is_complete: '',
        address: '',
        description: '',
        start_work: '',
        end_work: '',
        radius: '',
        lat: '',
        lng: '',
        company_id: null,
      },
      addressSearch: '',
      address: {
        serch: '',
        selectOptions: [],
        active: {},
        timer: null
      },
      map: {},
      placeMark: {},
      myCircle: null,
      rules: {
        title: [
          {
            required: true,
            message: 'Обязательное поле',
            trigger: ['blur', 'change'],
          },
        ],
        address: [
          {
            required: true,
            message: 'Обязательное поле',
            trigger: ['blur', 'change'],
          },
        ],
        is_complete: [
          {
            required: true,
            message: 'Обязательное поле',
            trigger: ['blur', 'change'],
          },
        ],
        description: [
          {
            required: true,
            message: 'Обязательное поле',
            trigger: ['blur', 'change'],
          },
        ],
        company_id: [
          {
            required: true,
            message: 'Обязательное поле',
            trigger: ['blur', 'change'],
          },
        ],
        start_work: [
          {
            required: true,
            message: 'Обязательное поле',
            trigger: ['blur', 'change'],
          },
        ],
        end_work: [
          {
            required: true,
            message: 'Обязательное поле',
            trigger: ['blur', 'change'],
          },
        ],
        radius: [
          {
            required: true,
            message: 'Обязательное поле',
            trigger: ['blur', 'change'],
          },
        ],
      },
      isDisabled: false,
    }
  },
  computed: {
    companiesList() {
      return this.$store.state.Projects.companiesList
    },
    currentCompanyOfUser() {
      return this.$store.state.Auth.currentCompanyOfUser
    },
    role() {
      return this.$store.state.Auth.userRole
    },
    radius() {
      return this.form.radius
    },
  },
  watch: {
    radius(val) {
      if (this.myCircle && val) {
        this.myCircle.geometry.setRadius(val)
      }
    },
    dataInfo: {
      deep: true,
      handler() {
        for (const key in this.form) {
          this.form[key] = this.dataInfo[key]
        }
        this.loadData();
      },
    },
  },
  methods: {
    submitForm() {
      this.isDisabled = true
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.dataInfo ? this.editProject() : this.createProject()
        } else {
          this.isDisabled = false
          return false
        }
      })
    },
    editProject() {
      this.$store
        .dispatch('Projects/editProject', {
          id: this.$route.params.id,
          data: this.form,
        })
        .then(() => {
          this.$notify({
            title: 'Успех',
            message: 'Изменения сохранены',
            type: 'success',
          })
          this.isDisabled = false
        })
        .catch(err => {
          console.log(err)
          this.$notify({
            title: 'Ошибка',
            message: 'Ошибка, попробуйте еще раз',
            type: 'error',
          })
          this.isDisabled = false
        })
    },
    createProject() {
      this.form.lat = this.coords[0]
      this.form.lng = this.coords[1]
      if (this.currentCompanyOfUser) {
        delete this.form.company_id
      }
      this.$store
        .dispatch('Projects/createProject', {
          data: this.form,
        })
        .then(data => {
          this.isDisabled = false
          if (data.data.data.id) {
            this.$router.push({ path: '/projects/' + data.data.data.id })
          }
          this.$notify({
            title: 'Успех',
            message: 'Проект создан',
            type: 'success',
          })
        })
        .catch(err => {
          console.log(err)
          this.$notify({
            title: 'Ошибка',
            message: 'Ошибка, попробуйте еще раз',
            type: 'error',
          })
          this.isDisabled = false
        })
    },

    querySearch(queryString, cb) {
      this.form.address = queryString;
      if (this.timer) {
        clearTimeout(this.timer);
      }
      let time = queryString.length < 3 ? 2000 : 800;
      this.timer = setTimeout(async () => {
        let result = await this.searchMap();
        cb(result);
      }, time)
    },
    searchLocationCoord(coord) {
      ymaps.geocode(coord, {
        results: 1
      }).then((res) => {
        if (res.geoObjects.get(0)) {
          let coords = res.geoObjects.get(0).geometry.getCoordinates();
          let properties = res.geoObjects.get(0).properties.getAll();

          this.form.address = properties.text;
        }
      });
    },
    handleSelect(item) {
      let coords = item.coords;
      this.placeMark.geometry.setCoordinates(coords)
      this.myCircle.geometry.setCoordinates(coords)
      this.map.setCenter(item.coords);
      this.form.address = item.value;
      this.coords = coords;
      this.form.lat = this.coords[0];
      this.form.lng = this.coords[1];
    },

    async searchMap() {
      var searchQuery = this.form.address;

      let promise = new Promise((resolve) => {
        let amountResult = 5;
        let result = []
        ymaps.geocode(searchQuery, { results: amountResult }).then(function (res) {
          for (let i = 0; i < amountResult; i++) {
            if (res.geoObjects.get(i)) {
              let coords = res.geoObjects.get(i).geometry.getCoordinates();
              let properties = res.geoObjects.get(i).properties.getAll();
              result.push({
                coords,
                properties,
                value: properties.text
              })
            }
          }
          resolve(result);
        });
      })

      return promise;
    },
    checkCoordToDefault() {
      if (!Array.isArray(this.coords)) {
        this.coords = [55.75165026149063, 37.62172952704088]
      }
    },

    async loadData() {
      this.checkCoordToDefault();
      if (!this.$store.state.Projects.companiesList) {
        await this.$store.dispatch('Projects/getCompaniesList')
      }
      if (this.dataInfo && this.form) {
        for (const key in this.form) {
          this.form[key] = this.dataInfo[key]
        }
        this.coords = [this.dataInfo.lat, this.dataInfo.lng]
      }

      this.placeMark.geometry.setCoordinates(this.coords)
      this.myCircle.geometry.setCoordinates(this.coords)
      this.map.setCenter(this.coords);

      this.map.events.add(
        'click',
        function (e) {
          const coords = e.get('coords')
          this.placeMark.geometry.setCoordinates(coords)
          this.myCircle.geometry.setCoordinates(coords)
          this.coords = coords
          this.form.lat = this.coords[0]
          this.form.lng = this.coords[1]
          this.searchLocationCoord([this.coords[0], this.coords[1]])
        },
        this,
      )
      this.myCircle.events.add(
        'click',
        function (e) {
          const coords = e.get('coords')
          this.placeMark.geometry.setCoordinates(coords)
          this.myCircle.geometry.setCoordinates(coords)
          this.coords = coords
          this.form.lat = this.coords[0]
          this.form.lng = this.coords[1]
          this.searchLocationCoord([this.coords[0], this.coords[1]])
        },
        this,
      )
      this.map.geoObjects.add(this.placeMark)
      this.map.geoObjects.add(this.myCircle)
    }
  },
  async created() {
    this.checkCoordToDefault();
    await loadYmap(this.settings)
    this.map = new ymaps.Map('map', {
      center: this.coords,
      zoom: 18,
    })
    this.placeMark = new ymaps.Placemark(
      this.coords,
      {
        zIndex: 2,
      },
      {
        iconLayout: 'default#image',
        iconImageHref: this.marker,
        iconImageSize: [22.5, 55],
      },
    )
    this.myCircle = new ymaps.Circle(
      [this.coords, this.form.radius],
      {
        zIndex: 0,
      },
      {
        fillOpacity: 0,
        strokeColor: '#159bf1',
        strokeWidth: 1,
      },
    )
    this.loadData();


  },
}
</script>

<style scoped lang="scss">
@import 'src/assets/styles/helpers/variables';
@import 'src/assets/styles/helpers/mixin';

.project-card {
  &__form {
    &-data {
      display: flex;

      .el-autocomplete {
        width: 100%;
      }

      .el-select {
        width: 100%;

        &::v-deep .el-input__inner {
          background-color: #f2f9ff;
        }
      }

      &__info {
        width: 70%;
        padding-right: 2.4rem;
        display: flex;
        flex-wrap: wrap;

        &--left {
          width: 73%;
        }

        &--right {
          width: 27%;
          padding-left: 2.4rem;
        }

        &--bottom {
          width: 100%;
          margin-top: 2rem;
        }
      }

      &__map {
        width: 30%;
        display: flex;
        flex-direction: column;

        &-title {
          font-size: 1.4rem;
          line-height: 1.9rem;
          color: $form-def-label-color;
          margin-bottom: 0.4rem;
        }

        &-content {
          border: 1px solid #daebfb;
          border-radius: 4px;
          flex: 1 auto;

          .ymap-container {
            height: 100%;
          }
        }
      }
    }

    &-buttons {
      margin-top: 2.4rem;
      display: flex;
      justify-content: space-between;

      a {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: $screen-md-max) {
  .project-card__form-data__info--left {
    width: 60%;
  }

  .project-card__form-data__info--right {
    width: 40%;
  }
}

@media (max-width: $screen-sm-max) {
  .project-card__form-data {
    flex-wrap: wrap;
  }

  .project-card__form-data__info {
    padding-right: 0;
    width: 100%;
  }

  .project-card__form-data__map {
    width: 100%;
    margin-top: 24px;
    height: 380px;
    max-height: 50vh;
  }
}

@media (max-width: $screen-xs-max) {
  .project-card__form-data__info--left {
    order: -15;
    width: 100%;
  }

  .project-card__form-data__info--right {
    width: 100%;
    padding-left: 0;
    margin-top: 24px;
  }

  .project-card__form-data__info--bottom {
    order: -10;
  }

  .project-card__form-data__map-content {
    border-radius: 0;
    margin: 0 -16px;
    border-left: none;
    border-right: none;
  }

  .project-card__form-buttons {
    flex-direction: column;
    align-items: center;

    .el-button {
      margin: 0;

      &.el-button--primary {
        width: 100%;
      }
    }

    .el-button:not(.el-button--primary) {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      order: 10;
      padding: 0;
      margin-top: 16px;
    }
  }
}
</style>
